import React, { FunctionComponent } from "react";
import { Link, FormattedMessage, useIntl } from "gatsby-plugin-react-intl";
import { StaticImage } from "gatsby-plugin-image";
import { FaRegFilePdf } from "react-icons/fa";

import Layout from "../shared/layout";
import SEO from "../shared/seo";

// TODO CSS modules
import "./index.scss";

import customerIcon from "../assets/images/icons/customer.svg";
import thoughtIcon from "../assets/images/icons/thought.svg";
import factoryIcon from "../assets/images/icons/factory.svg";
import diamondIcon from "../assets/images/icons/diamond.svg";
import piggyBankIcon from "../assets/images/icons/piggy-bank.svg";
import fileIcon from "../assets/images/icons/file.svg";
import certificateFile from "../assets/downloads/certificate.pdf";

const IndexPage: FunctionComponent = () => {
  const { formatMessage, locale } = useIntl();
  const strong = (text: string) => <strong>{text}</strong>;
  const ul = (html: string) => <ul>{html}</ul>;
  const li = (html: string) => <li>{html}</li>;

  return (
    <Layout>
      <SEO
        title={formatMessage({ id: "page.about" })}
        description={formatMessage({ id: "about.introduction.1" })}
        lang={locale}
      />
      <div className="cs_container">
        <div className="cs_row">
          <div className="cs_col">
            <h1 className="text-center" id="about">
              <FormattedMessage id="page.about" />
            </h1>
            <div className="cs_box-container">
              <div className="cs_box-col cs_box-container-image-wrapper cs_bot-margin-m">
                <StaticImage
                  className="rounded-corners silico"
                  src="../assets/images/silico.jpg"
                  alt={formatMessage({ id: "alt.silico-hq" })}
                  loading="eager"
                />
              </div>
              <div className="cs_box-col cs_box-container-content">
                <FormattedMessage id="about.introduction.1" tagName="p" values={{ strong }} />
                <FormattedMessage id="about.introduction.2" tagName="div" values={{ ul, li, strong }} />
              </div>
            </div>
            <div className="cs_box-container">
              <div className="cs_box-col cs_box-container-content">
                <FormattedMessage id="about.introduction.3" tagName="div" values={{ ul, li, strong }} />
                <FormattedMessage id="about.introduction.4" tagName="p" values={{ strong }} />
              </div>
              <div className="cs_box-col cs_box-container-image-wrapper cs_bot-margin-m">
                <StaticImage
                  className="rounded-corners product-example"
                  src="../assets/images/product.png"
                  alt={formatMessage({ id: "alt.product-picture" })}
                  loading="eager"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="cs_container full cs_bg-dark">
        <div className="cs_row">
          <div className="cs_container">
            <div className="cs_row">
              <div className="cs_col">
                <h1 className="text-center">
                  <FormattedMessage id="about.why-us.title" />
                </h1>
                <p className="cs_bot-margin-m justify">
                  <FormattedMessage id="about.why-us.description" />
                </p>
                <div className="cs_circle-container cs_bot-margin-m">
                  <div className="cs_circle-item">
                    <div className="cs_circle-item-icon">
                      <img src={customerIcon} alt={formatMessage({ id: "alt.icon" })} />
                    </div>
                    <div className="cs_circle-item-content">
                      <FormattedMessage id="about.why-us.reason.1" />
                    </div>
                  </div>
                  <div className="cs_circle-item">
                    <div className="cs_circle-item-icon">
                      <img src={thoughtIcon} alt={formatMessage({ id: "alt.icon" })} />
                    </div>
                    <div className="cs_circle-item-content">
                      <FormattedMessage id="about.why-us.reason.2" />
                    </div>
                  </div>
                  <div className="cs_circle-item">
                    <div className="cs_circle-item-icon">
                      <img src={factoryIcon} alt={formatMessage({ id: "alt.icon" })} />
                    </div>
                    <div className="cs_circle-item-content">
                      <FormattedMessage id="about.why-us.reason.3" />
                    </div>
                  </div>
                  <div className="cs_circle-item">
                    <div className="cs_circle-item-icon">
                      <img src={diamondIcon} alt={formatMessage({ id: "alt.icon" })} />
                    </div>
                    <div className="cs_circle-item-content">
                      <FormattedMessage id="about.why-us.reason.4" />
                    </div>
                  </div>
                  <div className="cs_circle-item">
                    <div className="cs_circle-item-icon">
                      <img src={piggyBankIcon} alt={formatMessage({ id: "alt.icon" })} />
                    </div>
                    <div className="cs_circle-item-content">
                      <FormattedMessage id="about.why-us.reason.5" />
                    </div>
                  </div>
                  <div className="cs_circle-item">
                    <div className="cs_circle-item-icon">
                      <img src={fileIcon} alt={formatMessage({ id: "alt.icon" })} />
                    </div>
                    <div className="cs_circle-item-content">
                      <FormattedMessage id="about.why-us.reason.6" />
                    </div>
                  </div>
                </div>
                <p className="cs_bot-margin-m text-center">
                  <a
                    href={certificateFile}
                    className="btn btn-lg btn-outline-light pdf-link"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FaRegFilePdf />
                    <FormattedMessage id="about.why-us.download-certificate" />
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="cs_container">
        <div className="cs_row">
          <div className="cs_container">
            <h1 className="text-center cs_bot-margin-l">
              <FormattedMessage id="about.products.title" />
            </h1>
            <div className="cs_box-container round cs_bot-margin-l">
              <div className="cs_box-col cs_box-container-image-wrapper cs_bot-margin-s">
                <StaticImage
                  src="../assets/images/products/product-mvq-thumb.jpg"
                  alt={formatMessage({ id: "about.products.mvq.title" })}
                />
              </div>
              <div className="cs_box-col cs_box-container-content">
                <FormattedMessage id="about.products.mvq.title" tagName="h2" />
                <p className="justify">
                  <FormattedMessage id="about.products.mvq.description" />
                </p>
                <Link to="/products#mvq">
                  <FormattedMessage id="about.products.read-more" />
                </Link>
              </div>
            </div>
            <div className="cs_box-container round reverse cs_bot-margin-l">
              <div className="cs_box-col cs_box-container-image-wrapper cs_bot-margin-s">
                <StaticImage
                  src="../assets/images/products/product-tpe-thumb.jpg"
                  alt={formatMessage({ id: "about.products.tpe.title" })}
                />
              </div>
              <div className="cs_box-col cs_box-container-content">
                <FormattedMessage id="about.products.tpe.title" tagName="h2" />
                <p className="justify">
                  <FormattedMessage id="about.products.tpe.description" />
                </p>
                <Link to="/products/#tpe">
                  <FormattedMessage id="about.products.read-more" />
                </Link>
              </div>
            </div>
            <div className="cs_box-container round cs_bot-margin-l">
              <div className="cs_box-col cs_box-container-image-wrapper cs_bot-margin-s">
                <StaticImage
                  src="../assets/images/products/product-mixes-thumb.jpg"
                  alt={formatMessage({ id: "about.products.mixes.title" })}
                />
              </div>
              <div className="cs_box-col cs_box-container-content">
                <FormattedMessage id="about.products.mixes.title" tagName="h2" />
                <p className="justify">
                  <FormattedMessage id="about.products.mixes.description" />
                </p>
              </div>
            </div>
            <div className="cs_box-container round reverse cs_bot-margin-l">
              <div className="cs_box-col cs_box-container-image-wrapper cs_bot-margin-s">
                <StaticImage
                  src="../assets/images/products/product-bentosil-thumb.jpg"
                  alt={formatMessage({ id: "about.products.bentosil.title" })}
                />
              </div>
              <div className="cs_box-col cs_box-container-content">
                <FormattedMessage id="about.products.bentosil.title" tagName="h2" />
                <p className="justify">
                  <FormattedMessage id="about.products.bentosil.description" />
                </p>
                <Link to="/products#bentosil">
                  <FormattedMessage id="about.products.read-more" />
                </Link>
              </div>
            </div>
            <div className="cs_box-container round cs_bot-margin-l">
              <div className="cs_box-col cs_box-container-image-wrapper cs_bot-margin-s">
                <StaticImage
                  src="../assets/images/products/product-swellsil-thumb.jpg"
                  alt={formatMessage({ id: "about.products.swellsil.title" })}
                />
              </div>
              <div className="cs_box-col cs_box-container-content">
                <FormattedMessage id="about.products.swellsil.title" tagName="h2" />
                <p className="justify">
                  <FormattedMessage id="about.products.swellsil.description" />
                </p>
                <Link to="/products#swellsil">
                  <FormattedMessage id="about.products.read-more" />
                </Link>
              </div>
            </div>
            <div className="cs_box-container round reverse cs_bot-margin-l">
              <div className="cs_box-col cs_box-container-image-wrapper cs_bot-margin-s">
                <StaticImage
                  src="../assets/images/products/product-silbut-uni-thumb.jpg"
                  alt={formatMessage({ id: "about.products.silbut-uni.title" })}
                />
              </div>
              <div className="cs_box-col cs_box-container-content">
                <FormattedMessage id="about.products.silbut-uni.title" tagName="h2" />
                <p className="justify">
                  <FormattedMessage id="about.products.silbut-uni.description" />
                </p>
                <Link to="/products#silbut-uni">
                  <FormattedMessage id="about.products.read-more" />
                </Link>
              </div>
            </div>
            <div className="cs_box-container round cs_bot-margin-l">
              <div className="cs_box-col cs_box-container-image-wrapper cs_bot-margin-s">
                <StaticImage
                  src="../assets/images/products/product-firelock-thumb.jpg"
                  alt={formatMessage({ id: "about.products.firelock.title" })}
                />
              </div>
              <div className="cs_box-col cs_box-container-content">
                <FormattedMessage id="about.products.firelock.title" tagName="h2" />
                <p className="justify">
                  <FormattedMessage id="about.products.firelock.description" />
                </p>
              </div>
            </div>
            <div className="cs_box-container round reverse cs_bot-margin-l">
              <div className="cs_box-col cs_box-container-image-wrapper cs_bot-margin-s">
                <StaticImage
                  src="../assets/images/products/product-paste-thumb.jpg"
                  alt={formatMessage({ id: "about.products.paste.title" })}
                />
              </div>
              <div className="cs_box-col cs_box-container-content">
                <FormattedMessage id="about.products.paste.title" tagName="h2" />
                <p className="justify">
                  <FormattedMessage id="about.products.paste.description" />
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default IndexPage;
